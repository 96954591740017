import LocalStorageTTL from './LocalStorageTTL.js';

class TokenService {
  getToken() {
    return LocalStorageTTL.getWithExpiry("token");
  }
  setToken(token) {
    LocalStorageTTL.setWithExpiry("token", (token));
  }
  removeToken() {
    localStorage.removeItem("token");
  }
}
export default new TokenService();
