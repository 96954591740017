import React from 'react'
import Logo from '../logo.png';

const Header = () => {
  return (
    <div className="app-logo">
        <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
        <label className="app-logo-text">新蒲崗雙喜街1號安田中心20樓02室</label>
        <label className="app-logo-text">Unit 02 20/F On Tin centre, 1 Sheung Hei street, San Po Kong, Kowloon</label>
        <a className="app-logo-text" href="mailto:hello@212studio.co">hello@212studio.co</a>
        <br/>
    </div>
  )
}

export default Header