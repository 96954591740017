import React from 'react'
// import '../Pages/Account/Profile.css'
import Logo from '../logo.png';
import image1 from '../img/venuePreview_1.png'
import image2 from '../img/venuePreview_2.png'
import image3 from '../img/venuePreview_3.png'
import image4 from '../img/venuePreview_4.png'
import image5 from '../img/venuePreview_5.png'
import { Height } from '@mui/icons-material';

const Header = () => {
  return (
    <div className="app-logo">

        <img className="app-logo-img" alt="212 Studio" src={Logo} onClick={event =>  window.location.href='/home'}/>
        <label className="app-logo-text">新蒲崗雙喜街1號安田中心20樓02室</label>
        <label className="app-logo-text">Unit 02 20/F On Tin centre, 1 Sheung Hei street, San Po Kong, Kowloon</label>
        <a className="app-logo-text" href="mailto:hello@212studio.co">hello@212studio.co</a>
        <br/>
        <div className="app-venue-preview-row">
          <img className="app-venue-preview-img" src={image1}/>
          <img className="app-venue-preview-img" src={image2}/>
          <img className="app-venue-preview-img" src={image3}/>
          <img className="app-venue-preview-img" src={image4}/>
          <img className="app-venue-preview-img" src={image5}/>
        </div>
        <br/>
    </div>
  )
}

export default Header