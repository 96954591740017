import React, { useState } from 'react';

import logo from './logo.png';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";

import useToken from './App/useToken.js';
import useIsFirstLogin from './App/useIsFirstLogin.js';
import useLoginAllowed from './App/useLoginAllowed.js';

import Home from './Pages/Home.js';
import Login from './Pages/Home/Login.js';
import Register from './Pages/Home/Register.js';
import ForgetPassword from './Pages/Home/ForgetPassword.js';

import Rental from './Pages/Rental/Rental.js';
import RentalTable from './Pages/Rental/RentalTable.js';
import RentalPayment from './Pages/Rental/RentalPayment.js';
import RentalPaymentStripe from './Pages/Rental/RentalPaymentStripe.js';
import RentalPaymentFPS from './Pages/Rental/RentalPaymentFPS.js';
import RentalPaymentPayme from './Pages/Rental/RentalPaymentPayme.js';
import RentalPaymentSuccess from './Pages/Rental/RentalPaymentSuccess.js';

import BookingTable from './Pages/Booking/BookingTable.js';

import BookingDetails from './Pages/Booking/BookingDetails.js';
import BookingPayment from './Pages/Booking/BookingPayment.js';
import BookingPaymentStripe from './Pages/Booking/BookingPaymentStripe.js';
import BookingPaymentFPS from './Pages/Booking/BookingPaymentFPS.js';
import BookingPaymentPayme from './Pages/Booking/BookingPaymentPayme.js';
import BookingPaymentSuccess from './Pages/Booking/BookingPaymentSuccess.js';

import Profile from './Pages/Account/Profile.js';
import ProfileEdit from './Pages/Account/ProfileEdit.js';
import ProfileTokenPackage from './Pages/Account/ProfileTokenPackage.js';
import ProfilePayment from './Pages/Account/ProfilePayment.js';
import ProfilePaymentStripe from './Pages/Account/ProfilePaymentStripe.js';
import ProfilePaymentFPS from './Pages/Account/ProfilePaymentFPS.js';
import ProfilePaymentPayme from './Pages/Account/ProfilePaymentPayme.js';
import ProfilePaymentSuccess from './Pages/Account/ProfilePaymentSuccess.js';
import ProfileRecordDetails from './Pages/Account/ProfileRecordDetails.js';

import PublicProfile from './Pages/Account/PublicProfile.js';

import TokenService from './TokenService.js';
import LocalStorageTTL from './LocalStorageTTL.js';

import EquipmentGallery from './Pages/Rental/EquipmentGallery.js';


function setToken(userToken) {
  TokenService.setToken(userToken);
}
function getToken() {
  return TokenService.getToken();
}
function App() {
  const { token, setToken } = useToken();
  const visitorName = LocalStorageTTL.getWithExpiry('visitorName');
  const visitorPhone = LocalStorageTTL.getWithExpiry('visitorPhone');
  if(!token || token == "visitor_token") {
    if(visitorName&&visitorPhone){
      //--Role: VISTOR /w NAME
      return (
        <Router basename={'/home'}>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/rent" element={<Rental />} />
            <Route path="/rent/:room_id" element={<RentalTable />} />
            <Route path="/rent/:room_id/payment" element={<RentalPayment />} />
            <Route path="/rent/:room_id/payment/card" element={<RentalPaymentStripe />} />
            <Route path="/rent/:room_id/payment/fps" element={<RentalPaymentFPS />} />
            <Route path="/rent/:room_id/payment/payme" element={<RentalPaymentPayme />} />
            <Route path="/rent/:rental_id/success" element={<RentalPaymentSuccess />} />
            <Route path="/book" element={<BookingTable />} />
            <Route path="/book/:class_id/:date/details" element={<BookingDetails />} />
            <Route path="/book/:class_id/:date/payment" element={<BookingPayment />} />
            <Route path="/book/:class_id/:date/payment/card" element={<BookingPaymentStripe />} />
            <Route path="/book/:class_id/:date/payment/fps" element={<BookingPaymentFPS />} />
            <Route path="/book/:class_id/:date/payment/payme" element={<BookingPaymentPayme />} />
            <Route path="/book/:booking_id/success" element={<BookingPaymentSuccess />} />
            <Route path="/forgot" element={<ForgetPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/checkin/:uid" element={<PublicProfile />} />
            <Route path="*" element={<Login />} />
            <Route path="/rent/equi_list" element={<EquipmentGallery />} />

          </Routes>
        </Router>
      );
    }else{
      //--Role: VISTOR
      return (
        <Router basename={'/home'}>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/rent" element={<Rental />} />
            <Route path="/rent/:room_id" element={<RentalTable />} />
            <Route path="/book" element={<BookingTable />} />
            <Route path="/book/:class_id/:date/details" element={<BookingDetails />} />
            <Route path="/forgot" element={<ForgetPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/checkin/:uid" element={<PublicProfile />} />
            <Route path="*" element={<Login />} />
            <Route path="/rent/equi_list" element={<EquipmentGallery />} />

          </Routes>
        </Router>
      );
    }
  } else {
    //--Role: MEMBER
    return (
      <Router basename={'/home'}>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/rent" element={<Rental />} />
          <Route path="/rent/:room_id" element={<RentalTable />} />
          <Route path="/rent/:room_id/payment" element={<RentalPayment />} />
          <Route path="/rent/:room_id/payment/card" element={<RentalPaymentStripe />} />
          <Route path="/rent/:room_id/payment/fps" element={<RentalPaymentFPS />} />
          <Route path="/rent/:room_id/payment/payme" element={<RentalPaymentPayme />} />
          <Route path="/rent/:rental_id/success" element={<RentalPaymentSuccess />} />

          <Route path="/book" element={<BookingTable />} />
          <Route path="/book/:class_id/:date/details" element={<BookingDetails />} />
          <Route path="/book/:class_id/:date/payment" element={<BookingPayment />} />
          <Route path="/book/:class_id/:date/payment/card" element={<BookingPaymentStripe />} />
          <Route path="/book/:class_id/:date/payment/fps" element={<BookingPaymentFPS />} />
          <Route path="/book/:class_id/:date/payment/payme" element={<BookingPaymentPayme />} />
          <Route path="/book/:booking_id/success" element={<BookingPaymentSuccess />} />

          <Route path="/account/:mode" element={<Profile />} />
          <Route path="/account/edit" element={<ProfileEdit />} />
          <Route path="/account/packages" element={<ProfileTokenPackage />} />
          <Route path="/account/payment" element={<ProfilePayment />} />
          <Route path="/account/payment/card" element={<ProfilePaymentStripe />} />
          <Route path="/account/payment/fps" element={<ProfilePaymentFPS />} />
          <Route path="/account/payment/payme" element={<ProfilePaymentPayme />} />
          <Route path="/account/payment/:token_id/success" element={<ProfilePaymentSuccess />} />

          <Route path="/account/record/:record_type" element={<ProfileRecordDetails />} />

          <Route path="/checkin/:uid" element={<PublicProfile />} />
          <Route path="*" element={<Home />} />
          <Route path="/rent/equi_list" element={<EquipmentGallery />} />


        </Routes>
      </Router>
    );
  }
}

export default App;
