import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import Logo from '../../logo.png';
import CreditCardImg from '../../img/CreditCardImg.png';
import FpsImg from '../../img/FpsImg.png';
import PaymeImg from '../../img/PaymeImg.png';
import TokenImg from '../../img/TokenImg.png';
import TokenIcon from '../../img/icon-DancingTicket.png';

import Header from '../../components/Header.js';

export default function RentalPayment() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const token = TokenService.getToken();
  const visitorName = LocalStorageTTL.getWithExpiry('visitorName');
  const visitorPhone = LocalStorageTTL.getWithExpiry('visitorPhone');
  const { room_id } = useParams();
  const rentalDate = LocalStorageTTL.getWithExpiry('rentalDate');
  const rentalDayIndex = LocalStorageTTL.getWithExpiry('rentalDayIndex');
  const rentalStartTime = LocalStorageTTL.getWithExpiry('rentalStartTime');
  const rentalEndTime = LocalStorageTTL.getWithExpiry('rentalEndTime');
  const rentalRemarks = LocalStorageTTL.getWithExpiry('rentalRemarks');
  const rentalRoom = LocalStorageTTL.getWithExpiry('rentalRoom');
  const rentalDuration = LocalStorageTTL.getWithExpiry('rentalDuration');
  const rentalRoomPrice = LocalStorageTTL.getWithExpiry('rentalRoomPrice');
  const rentalRoomPriceNc = LocalStorageTTL.getWithExpiry('rentalRoomPriceNc');
  // const rentalToken = LocalStorageTTL.getWithExpiry('rentalToken');

  // Start added by Cookie on 2024-07-22 issue #001 - add-on equip
  const addOnItemList = LocalStorageTTL.getWithExpiry('addOnItemListStr')!=null?
    JSON.parse(LocalStorageTTL.getWithExpiry('addOnItemListStr')):[];

  const rentalType = LocalStorageTTL.getWithExpiry('rentalType');
  // console.log(rentalType)
  const rentalRoomHrPrice = rentalType === 'commercial' ? rentalRoomPrice : rentalRoomPriceNc;
  LocalStorageTTL.setWithExpiry('rentalRoomHrPrice', rentalRoomHrPrice);
  const rentalTypeName = rentalType === 'commercial' ? '商用 Commercial Use' : '非商用 Non-commercial Use';

  const addOnItemListRemark = addOnItemList.filter(item => item.isChecked).map(item => `[${item.categoryName}] - ${item.name} (+$${item.price})`).join('\n');
  const rentalPaymentRemarks = rentalRoom + ' - ' + rentalTypeName + ' ' + rentalDuration + '小時 ($' + rentalRoomHrPrice*rentalDuration + ')\n' + addOnItemListRemark;
  // console.log(rentalPaymentRemarks);
  LocalStorageTTL.setWithExpiry('rentalPaymentRemarks', rentalPaymentRemarks);

  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  
  const [landingModal, setLandingModal] = React.useState(true);
  const openLandingModal = () => setLandingModal(true);
  const closeLandingModal = () => setLandingModal(false);

  const genPriceView = () => {

    if(isAnyAddOnItem()){
      return (
        <>
          <div className="app-payment-details-content">
            
              {rentalRoom} - {rentalTypeName} (${rentalRoomHrPrice*rentalDuration})
          </div>

          {addOnItemList.map((item)=>{
            if(item.isChecked)
              return (
                <div key={item.id} style={{fontSize:'80%'}} className="app-payment-details-content">
                  [{item.categoryName}] - {item.name} (+${item.price})
                </div>
              )
          })}
          <hr/>
          <div className="app-payment-details-footer">
             Total: HKD${rentalRoomHrPrice*rentalDuration + countAddOnTotal()}
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="app-payment-details-content">
              {rentalRoom} - {rentalTypeName} 
          </div>
          <div className="app-payment-details-footer">
             HKD${rentalRoomHrPrice*rentalDuration}
          </div>
        </>
      )
    }
  }

  const isAnyAddOnItem = () => {
    var isAny = false;

    if(addOnItemList!=null)
      addOnItemList.forEach(item => {
        if(item.isChecked)
          isAny = true;
      });

    return isAny;
  }

  const countAddOnTotal = () => {
    var addOnTotal = 0;
    addOnItemList.forEach(item => {
      if(item.isChecked)
        addOnTotal += Number(item.price);
    });
    LocalStorageTTL.setWithExpiry('rentalAddOnTotal', addOnTotal);
    return addOnTotal;
  }
  // End added by Cookie on 2024-07-22 issue #001 - add-on equip

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  // console.log("getSessionValue");
  if(rentalDate){
    //--Set day index
    var display_day = "Sun";
    if(rentalDayIndex == 1){
      display_day = "Mon";
    }else if (rentalDayIndex == 2) {
      display_day = "Tue";
    }else if (rentalDayIndex == 3) {
      display_day = "Wed";
    }else if (rentalDayIndex == 4) {
      display_day = "Thu";
    }else if (rentalDayIndex == 5) {
      display_day = "Fri";
    }else if (rentalDayIndex == 6) {
      display_day = "Sat";
    }
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Header />
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-topic">
                  <p>付款方法</p>
                  <p>Payment Method</p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  {/* <Grid item xs={4} sm={4} md={4} lg={4}>
                    <button
                      type="submit"
                      className="app-payment-container-button app-payment-container-visa"
                      onClick={event =>  window.location.href='payment/card'}
                    >
                      <img className="" src={CreditCardImg}/>
                    </button>
                  </Grid> */}
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <button
                      type="submit"
                      className="app-payment-container-button app-payment-container-fps"
                      onClick={event =>  window.location.href='payment/fps'}
                    >
                      <img className="" src={FpsImg}/>
                    </button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <button
                      type="submit"
                      className="app-payment-container-button app-payment-container-payme"
                      onClick={event =>  window.location.href='payment/payme'}
                    >
                      <img className="" src={PaymeImg}/>
                    </button>
                  </Grid>
                </Grid>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3',
                    marginTop:'16px'
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                      variant="body1"
                    >
                      <div className="app-payment-details-header">
                        {rentalDate}  [{display_day}]
                      </div>
                      <div className="app-payment-details-header">
                        {rentalStartTime} - {rentalEndTime}
                      </div>
                      <hr/>
                      <div className="app-payment-details-content">
                        {rentalRemarks}
                      </div>
                      {/* Start added by Cookie on 2024-07-22 issue #001 - add-on equip */}
                        {genPriceView()}
                      {/* End added by Cookie on 2024-07-22 issue #001 - add-on equip */}
                    </Typography>
                  </CardContent>
                </Card>
                <div className="app-bottom-container-booking">
                  <div className="app-bottom-container-booking-text">*一但確認預約，將不可進行更改。</div>
                  <div className="app-bottom-container-booking-text">*Once confirm, it cannot be undo.</div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
          <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={landingModal}
              onClose={closeLandingModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={landingModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    確認預約詳情 
                    <br/>
                    Confirm Booking Details
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    付款後不可更改，請確認預約詳情。
                    <br/>
                    Booking cannot be changed after payment, please confirm the details.
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" style={{padding: "10px", height: "78px"}} onClick={closeLandingModal}>接受並繼續付款 <br/>Accept and Proceed Payment</button>
                </Box>
              </Fade>
            </Modal>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
