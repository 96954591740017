import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import {Elements, useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import Logo from '../../logo.png';
import CreditCardImg from '../../img/CreditCardImg.png';
import FpsImg from '../../img/FpsImg.png';
import PaymeImg from '../../img/PaymeImg.png';
import TokenImg from '../../img/TokenImg.png';
import TokenIcon from '../../img/icon-DancingTicket.png';

import SampleCard from '../../img/Sample-Card.png';

import { TextField } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import InputAdornment from '@mui/material/InputAdornment';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

import Header from '../../components/Header.js';

export default function ProfilePaymentStripe() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const [name, setName] = useState();
  const [card, setCard] = useState();
  const [expiredDate, setExpiredDate] = useState();
  const [cvv, setCvv] = useState();
  const selectedPackage = JSON.parse(LocalStorageTTL.getWithExpiry('selectedPackage'));
  const token_num = selectedPackage.token_num;
  const price = selectedPackage.price;
  const expiry_day_period = selectedPackage.expiry_day_period;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  //--Stripe
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const [clientSecret, setClientSecret] = useState("");
  const stripePromise = loadStripe('pk_live_51PjGu7DodUurZqBQIz8GfP57AgsIfWuqwoQMJqfgQqxdiqPMmUcvCHqtVZM4hN1CF496BfAUyjACWdH70emgYMzd00JMcCFqya');
  const appearance = {
    theme: 'night',
    shapes: {
     borderRadius: 12,
     borderWidth: 0.5,
    },
    primaryButton: {
     shapes: {
      borderRadius: 20,
     },
    },
  };
  const options = {
    clientSecret,
    appearance,
  };
  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const paymentHandler = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://212studio-venue.01tech.hk/home/",
        },
        redirect: 'if_required'
      });
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        await tokenStripeRecord(token_num, price, expiry_day_period);
      }
    };
    return (
      <form id="stripe-form" onSubmit={paymentHandler}>
        <PaymentElement />
      </form>
    )
  };
  async function tokenStripeRecord(token_num, price, expiry_day_period) {
      try
      {
         axios.post('', {
          "name":"tokenStripeRecord",
          "param": {
              "token_num":token_num,
              "price":price,
              "expiry_day_period":expiry_day_period,
          },
          "headers": {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        })
        .then((response) => {
          console.log(response);
          if(response.status == 200){
            console.log(response.data);
            if(response.data.status == 200){
              LocalStorageTTL.setWithExpiry('token_detail', JSON.stringify(response.data.user.token_detail));
              window.location.replace("/home/account/payment/"+response.data.user.token_detail.id+"/success");
            } else {
              alert(response.data.message);
            }
          }else{
            alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
      catch (error)
      {
        alert(error);
      }
  };
  //--
  React.useEffect(() => {
    if(token_num){
      axios.post('', {
        "name":"tokenByStripe",
        "param": {
            "token_num":token_num,
            "price":price,
        },
        "headers": {
          'Authorization': 'Bearer ' + TokenService.getToken()
        },
      })
      .then((response) => {
        console.log(response);
        if(response.status == 200){
          console.log(response.data);
          if(response.data.status == 200){
            setClientSecret(response.data.paymentIntent);
          } else {
            alert(response.data.message);
          }
        }else{
          alert(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, []);
  if(clientSecret){
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Header />
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-topic">
                  <p>確認付款</p>
                  <p>Confirm Payment</p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-bottom">
                  <div className="app-bottom">
                    <div className="app-bottom-container">
                      <button type="button" className="app-bottom-container-back" onClick={event =>  window.location.href=("/home/account/payment")}>返回 Back</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-bottom">
                  <div className="app-bottom">
                    <div className="app-bottom-container">
                      <button form="stripe-form" type="submit" className="app-bottom-container-back black-button">確認 Confirm</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
