import React, { useState , useRef} from 'react';
import { useParams } from "react-router-dom";

import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import './BookingPaymentPayme.css';
import Logo from '../../logo.png';
import CreditCardImg from '../../img/CreditCardImg.png';
import FpsImg from '../../img/FpsImg.png';
import PaymeImg from '../../img/PaymeImg.png';
import TokenImg from '../../img/TokenImg.png';
import TokenIcon from '../../img/icon-DancingTicket.png';

import Header from '../../components/Header.js';

import { FileUploader } from "react-drag-drop-files";
import AWS from 'aws-sdk'
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function BookingPaymentPayme() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const visitorName = LocalStorageTTL.getWithExpiry('visitorName');
  const visitorPhone = LocalStorageTTL.getWithExpiry('visitorPhone');
  const [lesson, setLesson] = useState();
  const { class_id, date } = useParams();
  //--
  const buttonRef = useRef();
  //--popup modal
  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //--fileupload
  const [progress , setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "HEIC", "HEIF"];
  const fileChange = (file) => {
    buttonRef.current.disabled = true;
    setFile(file);
    //--Start s3 upload
    AWS.config.update({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY
    });
    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET},
        region: REGION,
        dirName: DIR_NAME
    });
    const current_time = Math.floor(new Date().getTime() / 1000);
    const random_index = (Math.floor(Math.random() * 900) + 100);
    const params = {
        ACL: 'public-read',
        Body: file,
        Bucket: S3_BUCKET,
        Key: DIR_NAME+"Record_"+current_time+"_"+random_index+"_"+file.name
    };
    myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .send((err) => {
            if (err){
              alert("Upload image fail! Please try another payment method.");
            }else{
              setImageUrl("https://s3.ap-northeast-2.amazonaws.com/com.01tech.share/"+params.Key);
            }
            buttonRef.current.disabled = false;
        });
  };
  const S3_BUCKET ='com.01tech.share';
  const REGION ='ap-northeast-2';
  const DIR_NAME = 'projects/212studio/user_image/';
  const ACCESS_KEY ='AKIAYEH7O4N7YWWJVYKM';
  const SECRET_ACCESS_KEY ='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';

  const handleSubmit = async e => {
    e.preventDefault();
    //--prevent multiple submit
    buttonRef.current.disabled = true;
    if(imageUrl){
      bookByPayme(
        lesson.cid,
        (date+" "+lesson.start_time),
        lesson.token,
        imageUrl,
        visitorName,
        visitorPhone
      );
    }else{
      buttonRef.current.disabled = false;
      handleOpen();
    }
  };
  async function bookByPayme(class_id, class_date, token, image_url, visitorName, visitorPhone) {
      try
      {
         axios.post('', {
          "name":"bookByPayme",
          "param": {
              "class_id":class_id,
              "class_date":class_date,
              "token":token,
              "image_url":image_url,
              "visitorName":visitorName,
              "visitorPhone":visitorPhone,
          },
          "headers": {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        })
        .then((response) => {
          console.log(response);
          if(response.status == 200){
            console.log(response.data);
            if(response.data.status == 200){
              LocalStorageTTL.setWithExpiry('booking_detail', JSON.stringify(response.data.user.booking_detail));
              window.location.replace("/home/book/"+response.data.user.booking_detail.id+"/success");
            } else {
              alert(response.data.message);
              buttonRef.current.disabled = false;
            }
          }else{
            alert(response.data.message);
            buttonRef.current.disabled = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
      catch (error)
      {
        alert(error);
      }
  };
  //--
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getLessonById',
          cid: class_id,
          headers: {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getLessonById");
          console.log(response.data.class);
          console.log("-------------");
          setLesson(response.data.class);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(lesson){
    console.log("getSessionValue");
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <form onSubmit={handleSubmit}>
              <Header />
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="app-page-topic">
                    <p>上載付款憑證</p>
                    <p>Upload Payment</p>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className="image_drop_block">
                    <FileUploader
                      classes="image_drop_zone"
                      label="點擊或拖曳至此 Click or drop"
                      handleChange={fileChange}
                      name="file"
                      types={fileTypes}
                      required='true'
                      hoverTitle="確認上傳"
                    />
                    <img className="app-logo-img" src={imageUrl}/>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      slots={{ backdrop: Backdrop }}
                      slotProps={{
                        backdrop: {
                          timeout: 500,
                        },
                      }}
                    >
                      <Fade in={open}>
                        <Box sx={modal_style}>
                          <Typography id="transition-modal-title" variant="h6" component="h2">
                            注意
                          </Typography>
                          <Typography id="transition-modal-title" variant="h6" component="h2">
                            Attention
                          </Typography>
                          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            請上傳相關的付款證明
                          </Typography>
                          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Please upload related payslip
                          </Typography>
                          <br/>
                          <button type="button" className="modal-bottom-button black-button" onClick={handleClose}>知道了 Got It</button>
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-page-bottom">
                    <div className="app-bottom">
                      <div className="app-bottom-container">
                        <div className="app-bottom-container-booking">
                          <div className="app-bottom-container-booking-text">*我們將會檢查閣下的付款證明截圖。</div>
                          <div className="app-bottom-container-booking-text">*The payment will be checked by our staff.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className="app-page-bottom">
                    <div className="app-bottom">
                      <div className="app-bottom-container">
                        <button type="button" className="app-bottom-container-back" onClick={event =>  window.location.href=('/home/book/'+class_id+'/payment')}>返回 Back</button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className="app-page-bottom">
                    <div className="app-bottom">
                      <div className="app-bottom-container">
                        <button ref={buttonRef} type="submit" className="app-bottom-container-back black-button" onClick={handleSubmit}>確認 Confirm</button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
            </form>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
