import * as React from 'react';
import Header from '../../components/Header.js';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Col from 'react-bootstrap/Col';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 6 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

export default function EquipmentGallery() {
    
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return(
        <div>
            <Header />
            <Col xs={12} sm={12} md={12} lg={12}>
                <div className="app-home-welcome-text">
                  <p>場地器材</p>
                </div>
            </Col>
            <hr/>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', margin: 'auto', width: 700}}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    
                    <Tab label="攝影背景紙2.72M" {...a11yProps(0)} />
                    <Tab label="讀稿機" {...a11yProps(1)} />
                    <Tab label="聚光鏡" {...a11yProps(2)} />
                    <Tab label="投影機" {...a11yProps(3)} />
                    <Tab label="閃光燈" {...a11yProps(4)} />
                    <Tab label="LED燈" {...a11yProps(5)} />

                </Tabs>
                <TabPanel value={value} index={0}>
                    <img src="https://212studio.01tech.hk/wp-content/uploads/2024/09/venue-equip_backdrop.png" alt="攝影背景紙2.72M" width="500" height="auto" />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <img src="https://212studio.01tech.hk/wp-content/uploads/2024/07/212-Studio-Equipment_2.jpeg" alt="讀稿機" width="500" height="auto" />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <img src="https://212studio.01tech.hk/wp-content/uploads/2024/07/212-Studio-Equipment_3.jpeg" alt="聚光鏡" width="500" height="auto" />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <img src="https://212studio.01tech.hk/wp-content/uploads/2024/07/212-Studio-Equipment_4.jpeg" alt="投影機" width="500" height="auto" />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <img src="https://212studio.01tech.hk/wp-content/uploads/2024/07/212-Studio-Equipment_6.jpeg" alt="閃光燈" width="500" height="auto" />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <img src="https://212studio.01tech.hk/wp-content/uploads/2024/07/212-Studio-Equipment_5.jpeg" alt="LED燈" width="500" height="auto" />
                </TabPanel>

            </Box>
        </div>
    )
}
