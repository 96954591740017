import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationZHHK from '../src/locales/zh-HK/translation.json';
import translationZHCN from '../src/locales/zh-CN/translation.json';

// the translations
const resources = {
  zh_hk: {
    translation: translationZHHK
  },
  zh_cn: {
    translation: translationZHCN
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    supportedLngs: ['zh_cn', 'zh_hk'],
    fallbackLng: "zh_hk",
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    }
  });
export default i18n;
