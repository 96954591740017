import { useState } from 'react';
import LocalStorageTTL from '../LocalStorageTTL.js';

export default function useToken() {
  const getToken = () => {
    const userToken = LocalStorageTTL.getWithExpiry('token');
    return userToken
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    LocalStorageTTL.setWithExpiry('token', userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
};
