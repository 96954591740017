import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { TextField } from '@mui/material';
import { Backdrop, Box, Paper, stackLayout, List, Grid, Modal, Fade } from '@mui/material';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";

import './RentalTable.css';
import Logo from '../../logo.png';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

import InputAdornment from '@mui/material/InputAdornment';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Header from '../../components/HeaderVenuePreview.js';

export default function RentalTable() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const token = TokenService.getToken();
  const dontShowRentalTable = LocalStorageTTL.getWithExpiry('dontShowRentalTable');
  const [room, setRoom] = useState();
  // Start added by Cookie on 2024-07-08 issue #001 - add-on equip    
  const [addOn, setAddOn] = useState({addOnItemCategoryList:[],addOnItemList:[]});
  // End added by Cookie on 2024-07-08 issue #001 - add-on equip  
  const { room_id } = useParams();
  //--Clear
  localStorage.removeItem('proceed_link');
  localStorage.removeItem('member_only');
  //--popup modal - Landing
  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  const [landingModal, setLandingModal] = React.useState(true);
  const openLandingModal = () => setLandingModal(true);
  const closeLandingModal = () => setLandingModal(false);
  //
  const closeAndRmbLandingModal = () => {
    LocalStorageTTL.setWithExpiry('dontShowRentalTable', 1);
    setLandingModal(false);
  };

 //Start added by Cookie on 2024-07-08 issue #001 - add-on equip
  const onAddOnItemChange = (itemId) => {
    var tempAddOn = Object.assign({}, addOn);
    tempAddOn['addOnItemList'].forEach((item)=>{
      if(item.id == itemId){
        if(item.isChecked){
          item.isChecked = false;
        } else {
          var overMaxErrMsg = checkIfAddOnCategoryReachMax(item.categoryId);
          if(overMaxErrMsg!=''){
            alert(overMaxErrMsg);
            item.isChecked = false;
          }
          else
            item.isChecked = true;
        }
      }
    })
    setAddOn(tempAddOn);
  }
  
  const checkIfAddOnCategoryReachMax = (categoryId) => {
    var category = null;
    var maxOption = 999;
    addOn['addOnItemCategoryList'].forEach((tempCategory)=>{
      if(categoryId == tempCategory.id){
        category = tempCategory;

        if(category.maxOption!=null)
          maxOption = category.maxOption;
      }
    })

    var selected = 0;
    addOn['addOnItemList'].forEach((item)=>{
      if(item.categoryId == categoryId && item.isChecked)
        selected++;
    })

    return (maxOption > selected)? '': category.name + '最多可選_項'.replace('_',maxOption);
  }
 //End added by Cookie on 2024-07-08 issue #001 - add-on equip
  //--
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getRoomDetails',
          rid: room_id,
          headers: {
            // 'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      if(response.status == 200){
        if(response.data.status == 200){
          // console.log("getRoomDetails");
          // console.log(response.data.room);
          // console.log("-------------");
          setRoom(response.data.room);
          //Start added by Cookie on 2024-07-08 issue #001 - add-on equip
          //init add on item
          if(response.data.addOn!=null && response.data.addOn!=undefined){
            response.data.addOn.addOnItemCategoryList.forEach((category)=>{
              response.data.addOn.addOnItemList.forEach((item)=>{
                if(item.categoryId==category.id){
                  item.isChecked = false;
                  item.categoryName = category.name;
                }
              })
            })
            setAddOn(response.data.addOn);
          }
          //End added by Cookie on 2024-07-08 issue #001 - add-on equip

        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(room){
    if(!token){
      return(
        <div className="app-wrapper">
          <div className="app-page">
            <Container>
              <Header />
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-rental-page-main">
                    {/* Start added by Cookie on 2024-07-15 issue #001 - add-on equip */}
                    <RentalTableBlock room={room} addOn={addOn} onAddOnItemChange={onAddOnItemChange} />
                    {/* End added by Cookie on 2024-07-15 issue #001 - add-on equip */}
                  </div>
                </Col>
              </Row>
            </Container>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={landingModal}
              onClose={closeLandingModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={landingModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    租用流程 Steps
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  從開始租用的時間區域點擊以選擇
                  <br/>
                  Click the required slot from Timetable
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  選擇結束時間並點「租用」進入付款程序
                  <br/>Choose rental period & click 'Rental'
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeLandingModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      );
    }else{
      return(
        <div className="app-wrapper">
          <div className="app-page">
            <Container>
              <Header />
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <div className="app-rental-page-main">
                    {/* Start added by Cookie on 2024-07-15 issue #001 - add-on equip */}
                    <RentalTableBlock room={room} addOn={addOn} onAddOnItemChange={onAddOnItemChange} />
                    {/* End added by Cookie on 2024-07-15 issue #001 - add-on equip */}
                  </div>
                </Col>
              </Row>
            </Container>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={dontShowRentalTable==1?false:landingModal}
              onClose={closeLandingModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={landingModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    租用流程 Steps
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                    從開始租用的時間區域點擊以選擇
                    <br/>
                    Click the required slot from Timetable
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                    選擇結束時間並點「租用」進入付款程序
                    <br/>Choose rental period & click 'Rental'
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button white-button" onClick={closeAndRmbLandingModal}>下次不再提示 Dont mention again</button>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeLandingModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>
          </div>
        </div>
      );
    }
  }else{
    return(<h3></h3>);
  }
};
function RentalTableBlock(props){
  const { t } = useTranslation();
  const token = TokenService.getToken();
  const visitorName = LocalStorageTTL.getWithExpiry('visitorName');
  const visitorPhone = LocalStorageTTL.getWithExpiry('visitorPhone');
  const { room_id } = useParams();
  //--popup modal - Rental
  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // Start added by Cookie on 2024-07-15 issue #001 - add-on equip
    width: '100vw',
    // End added by Cookie on 2024-07-15 issue #001 - add-on equip
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };

  if (window.innerWidth >= 992) {
    modal_style.width = '900px';
  } else {
    modal_style.width = '100vw';
  }

  const [rentalModal, setRentalModal] = React.useState(false);
  const openRentalModal = (arg) => {
    const year = arg.dateStr.substring(0,4);
    const month = arg.dateStr.substring(5,7);
    const day = arg.dateStr.substring(8,10);
    setRentalDate(dayjs(new Date(year, month-1, day)));
    const hh = arg.date.toLocaleString('en-GB').substring(12,14);
    const mm = arg.date.toLocaleString('en-GB').substring(15,17);
    setRentalStartTime(dayjs().set('hour', hh).set('minute', mm).startOf('minute'));
    //--add an hour for default
    arg.date.setHours(arg.date.getHours()+1);
    const new_hh = arg.date.toLocaleString('en-GB').substring(12,14);
    const new_mm = arg.date.toLocaleString('en-GB').substring(15,17);
    setRentalEndTime(dayjs().set('hour', new_hh).set('minute', new_mm).startOf('minute'));
    LocalStorageTTL.setWithExpiry('rentalDayIndex', arg.date.getDay());
    //--display
    setRentalModal(true);
  }
  const closeRentalModal = () => setRentalModal(false);
  //
  const [alertModal, setAlertModal] = React.useState(false);
  const openAlertModal = () => setAlertModal(true);
  const closeAlertModal = () => setAlertModal(false);
  //
  const [validationModal, setValidationModal] = React.useState(false);
  const openValidationModal = () => setValidationModal(true);
  const closeValidationModal = () => setValidationModal(false);
  //
  const [chosenModal, setChosenModal] = React.useState(false);
  const openChosenModal = () => setChosenModal(true);
  const closeChosenModal = () => setChosenModal(false);
  //
  const [noticeModal, setNoticeModal] = React.useState(false);
  const openNoticeModal = () => setNoticeModal(true);
  const closeNoticeModal = () => setNoticeModal(false);
  //
  const [rentalDate, setRentalDate] = useState();
  const [rentalStartTime, setRentalStartTime] = useState();
  const [rentalEndTime, setRentalEndTime] = useState();
  const [rentalRemarks, setRentalRemarks] = useState("");
  const [rentalToken, setRentalToken] = useState("1");
  //--
  const now = new Date();
  const handleDateClick = (arg) => { // bind with an arrow function
    //--Check preivous or future date
    if(arg.date.getTime()<now.getTime()){
      openAlertModal();
    }else{
      openRentalModal(arg);
    }
  }
  const rentalOnSubmit = () => {
    if(rentalStartTime==rentalEndTime){
      setValidationModal(true);
    }else{
      checkRentalTime();
    }
  }
  async function checkRentalTime() {
    try
    {
       axios.post('', {
        "name":"checkRentalTime",
        "param": {
            "room_id":room_id,
            "rentalDate":rentalDate.format('YYYY/MM/DD'),
            "rentalStartTime":rentalStartTime.format('HH:mm'),
            "rentalEndTime":rentalEndTime.format('HH:mm'),
            "rentalDayIndex":rentalDate.format('d')
        }
      })
      .then((response) => {
        console.log(response);
        if(response.status == 200){
          if(response.data.status == 200){
            //--SUCCESS
            LocalStorageTTL.setWithExpiry('rentalDate', rentalDate.format('YYYY/MM/DD'));
            LocalStorageTTL.setWithExpiry('rentalDayIndex', rentalDate.format('d'));
            LocalStorageTTL.setWithExpiry('rentalStartTime', rentalStartTime.format('HH:mm'));
            LocalStorageTTL.setWithExpiry('rentalEndTime', rentalEndTime.format('HH:mm'));
            LocalStorageTTL.setWithExpiry('rentalRemarks', rentalRemarks);
            LocalStorageTTL.setWithExpiry('rentalDuration', (rentalEndTime.format('H') - rentalStartTime.format('H'))+(rentalEndTime.format('mm') - rentalStartTime.format('mm'))/60);
            LocalStorageTTL.setWithExpiry('rentalType', rentalType);
            LocalStorageTTL.setWithExpiry('rentalToken', rentalToken);
            // Start added by Cookie on 2024-07-15 issue #001 - add-on equip
            LocalStorageTTL.setWithExpiry('addOnItemListStr', JSON.stringify(props.addOn.addOnItemList));

            // End added by Cookie on 2024-07-15 issue #001 - add-on equip
            if(!token){
              LocalStorageTTL.setWithExpiry('proceed_link', ('/home/rent/'+room_id)+'/payment');
            }else{
              localStorage.removeItem('proceed_link');
            }
            LocalStorageTTL.setWithExpiry('proceed_rentalTable_initialDate', rentalDate.format('YYYY-MM-DD'));
            window.location.href=('/home/rent/'+room_id)+'/payment';
          }else{
            openChosenModal();
            console.log(response.data.message);
          }
        }else{
          alert((i18n.language == 'zh_hk')?"連接出現問題！請聯絡相關技術人員！":"连接出现问题！请联络相关技术人员！");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    catch (error)
    {
      alert(error);
    }
  };
  const startTimeOnChange = (newValue) => {
    setRentalStartTime(newValue);
    console.log(newValue.format('H'));
    // console.log(rentalStartTime.format('H')+1);
    console.log(newValue.format('m'));
    setRentalEndTime(dayjs().set('hour', Number(newValue.format('H'))+2).set('minute', newValue.format('m')).startOf('minute'));
    setRentalEndTime(newValue);
    console.log(rentalEndTime);
  }

  // Start added by Cookie on 2024-07-15 issue #001 - add-on equip
  const genAddOnItemView = () => {
    const {addOnItemCategoryList,addOnItemList} = props.addOn;

    return addOnItemCategoryList.map((category)=>{
      return (
        <div key={'cat_' + category.id} style={{marginTop:'8px'}}>
          <div>{category.name}{category.maxOption?' (最多可選_項)'.replace('_',category.maxOption):''} :</div>

          {addOnItemList.map((item)=>{
            if(category.id == item.categoryId)
              return (
                <div key={'item_' + item.id}>
                  <input
                    type="checkbox"
                    id={`checkbox-${item.id}`}
                    checked={item.isChecked}
                    onChange={()=>props.onAddOnItemChange(item.id)}
                  />
                  {/* <label htmlFor={`checkbox-${item.id}`} style={{marginLeft:'8px'}}>{item.name + ' ($' + item.price + ')'}</label> */}
                  <label htmlFor={`checkbox-${item.id}`} style={{marginLeft:'8px'}}>{item.name}</label>

                </div>
              )
          })}
        </div>
        )
    })
  }
  // End added by Cookie on 2024-07-15 issue #001 - add-on equip

  const [rentalType, setRentalType] = useState('commercial');

  const handleRentalTypeChange = (event) => {
    const selectedRentalType = event.target.value;
    setRentalType(selectedRentalType)
    localStorage.setItem('rentalType', selectedRentalType);
    // console.log('selectedRentalType: '+ selectedRentalType)
  };

  const initialDate = LocalStorageTTL.getWithExpiry('proceed_rentalTable_initialDate')==""?dayjs().format('YYYY-MM-DD'):LocalStorageTTL.getWithExpiry('proceed_rentalTable_initialDate');
  const display = (props) => {
      const { room } = props;
      LocalStorageTTL.setWithExpiry('rentalRoom', room.room_name);
      LocalStorageTTL.setWithExpiry('rentalRoomPrice', room.room_price);
      LocalStorageTTL.setWithExpiry('rentalRoomPriceNc', room.room_price_nc);

      if(room !== undefined){
        return(
          <>
            <FullCalendar
              plugins={[ timeGridPlugin, interactionPlugin ]}
              events={room.classes}
              initialDate={initialDate}
              initialView="timeGridWeek"
              scrollTime="16:00:00"
              slotMinTime="9:00:00"
              slotMaxTime="24:00:00"
              slotLabelFormat= {{
                hour: '2-digit',
                minute: '2-digit',
                hour12:false
              }}
              eventTimeFormat= {{ // like '14:30:00'
                hour: '2-digit',
                minute: '2-digit',
                hour12:false
              }}
              weekends={true}
              customButtons={{
                  backButton: {
                    text: '返回 Back',
                    click: function() {
                      window.location.href='/home/rent'
                    },
                  },
                  titleButton: {
                    text: room.room_name,
                  },
                }}
              headerToolbar={{
                start: 'prev,next', // will normally be on the left. if RTL, will be on the right
                center: 'title',
                end: 'today', // will normally be on the right. if RTL, will be on the left
              }}
              allDayContent={{
                html:"全日"
              }}
              buttonText={{
                today:    '今天 Today',
                month:    'month',
                week:     'week',
                day:      'day',
                list:     'list'
              }}
              dateClick={handleDateClick}
            />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={rentalModal}
              onClose={closeRentalModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={rentalModal}>
                <Box sx={modal_style} style={{maxHeight: '95vh',overflow: 'auto'}}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    {room.room_name}
                  </Typography>
                  <div>
                    <div>
                      <form className="app-rental-page-form" noValidate>
                      <Typography sx={{ mt: 2 }}>
                        <Typography style={{ color: 'black', fontWeight: '600', textAlign: 'left' }}>租用時段</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              id="rental_date"
                              label="日期 Date"
                              value={rentalDate}
                              onChange={(date) => {setRentalDate(date)}}
                              views={['year', 'month', 'day']}
                              format="YYYY-MM-DD"
                              disablePast
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Typography>
                      <Typography sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['TimePicker', 'TimePicker']}>
                            <TimePicker
                              id="rental_start_time"
                              label="開始時間 Start time"
                              ampm={false}
                              minutesStep={30}
                              timeStep={{ hours: 1, minutes: 30, seconds: 5 }}
                              value={rentalStartTime}
                              onChange={(newValue) => startTimeOnChange(newValue)}
                              autoFocus={true}
                              views={['hours', 'minutes']}
                              minTime={dayjs().set('hour', 9).startOf('hour')}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <label className="app-logo-text">時間為24小時制，請留下正確的時間</label>
                      </Typography>
                      <Typography sx={{ mt: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['TimePicker', 'TimePicker']}>
                            <TimePicker
                              id="rental_end_time"
                              label="結束時間 End time"
                              ampm={false}
                              minutesStep={30}
                              timeStep={{ hours: 1, minutes: 30, seconds: 5 }}
                              value={rentalEndTime}
                              onChange={(newValue) => setRentalEndTime(newValue)}
                              views={['hours', 'minutes']}
                              minTime={rentalStartTime}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <label className="app-logo-text">Time format is 24hrs, Please choose the right time.</label>
                      </Typography>
                      <hr/>
                      <Typography style={{ textAlign: 'left' }}>
                        <FormControl>
                          <FormLabel 
                            id="renrtal-use-radio-buttons-group-label" 
                            style={{ color: 'black', fontWeight: '600' }}
                            aria-label="rental-type"
                            name="rental-type"
                            value={rentalType}
                            onChange={handleRentalTypeChange}
                          >
                            租用類型
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="renrtal-use-radio-buttons-group-label"
                            defaultValue="commercial"
                            name="radio-buttons-group"
                            value={rentalType}
                            onChange={(event) => handleRentalTypeChange(event)}
                          >
                            <FormControlLabel value="commercial" control={<Radio />} label={"商用 Commercial Use $"+room.room_price+"/hr"} />
                            <FormControlLabel value="nonCommercial" control={<Radio />} label={"非商用 Non-commercial Use $"+room.room_price_nc+"/hr"} />
                          </RadioGroup>
                        </FormControl>
                      </Typography>
                      </form>
                    </div>
                    <hr/>
                    <Typography sx={{ mt: 2 }} style={{ textAlign: 'left' }}>
                      <Typography style={{ color: 'black', fontWeight: '600'}}>租用器材 ($100)</Typography>
                      <div><a href="http://212studio-venue.01tech.hk/home/rent/equi_list/" target="_blank">按此查閱器材圖片 Click here to equipment gallery</a></div>
                      { genAddOnItemView() }
                    </Typography>
                  </div>
                  <hr/>
                  <Typography sx={{ mt: 2 }} style={{ textAlign: 'left' }}>
                      <Typography style={{ color: 'black', fontWeight: '600'}}>租用說明 Remarks</Typography>
                      <TextField
                        id=""
                        sx={{
                          "& .MuiInputBase-root": {
                            color: 'black',
                            width: '100%'
                          },
                          "& .MuiSvgIcon-root":{
                            color: 'black',
                          },
                          "& .MuiFormLabel-root":{
                            color: 'black',
                          },
                          "& .MuiInputBase-input:after":{
                            color: 'black',
                          },
                          "& .Mui-focused":{
                            color: 'black',
                          },
                          "& .Mui-focused:after":{
                            borderBottom: "1px solid white"
                          }
                         }}
                        placeholder="說明 Remarks"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BadgeOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                        color="warning"
                        focused
                        fullwidth
                        value={rentalRemarks}
                        onChange={e => setRentalRemarks(e.target.value)}
                      />
                    </Typography>

                  <br/>
                  <button type="button" className="modal-bottom-button white-button" onClick={closeRentalModal}>返回 Back</button>
                  <br/>
                  {/* <button type="button" className="modal-bottom-button black-button" onClick={() => rentalOnSubmit()}>租用 Rental</button> */}
                  <button type="button" className="modal-bottom-button black-button" onClick={() => openNoticeModal()}>租用 Rental</button>
                  </Box>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={alertModal}
              onClose={closeAlertModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={alertModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    (!)
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  請選擇將來的時段
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  Please choose the proper time
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeAlertModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={validationModal}
              onClose={closeValidationModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={validationModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    (!)
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  請選擇正確的時段
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  Please choose the proper time
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeValidationModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={chosenModal}
              onClose={closeChosenModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={chosenModal}>
                <Box sx={modal_style}>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    (!)
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  該時段已被租用
                  </Typography>
                  <Typography sx={{ mt: 2 }}>
                  The requested timeslot has been rented
                  </Typography>
                  <br/>
                  <button type="button" className="modal-bottom-button black-button" onClick={closeChosenModal}>知道了 Got it</button>
                </Box>
              </Fade>
            </Modal>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={noticeModal}
              onClose={closeNoticeModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={noticeModal}>
                <Box sx={modal_style} style={{maxHeight: '100%', overflow: 'auto'}}>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography id="transition-modal-title" variant="h6" component="h2">
                        租用細則
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ mt: 2 }} textAlign={"left"}>
                        1. 租場費並不包括燈光器材，背景紙。
                        <br/>
                        2. 租場費不包括打燈服務，攝影師，攝影助手，攝影服務及電腦等。
                        <br/>
                        3. 請保持影樓清潔並自行清理垃圾，否則收取$1500清潔費。
                        <br/>
                        4. 離場前請收拾所有影樓物品並放回原處，否則收取$1500整理費。
                        <br/>
                        5. 超時過15分鐘即以超時1小時計算，結束時間以客人離開影樓時間為準。
                        <br/>
                        6. 如客人不當使用器材或影樓物品令其損毀，須照價賠償。
                        <br/>
                        7. 如客人非以原預約之目的使用影樓，212 STUDIO 會即時收取額外費用。如客人拒絕，212 STUDIO 會即時禁止客人使用影樓。
                        <br/>
                        8. 212 STUDIO不會為客人的損傷或損失負上任何責任或賠償。
                        <br/>
                        9. 影樓內嚴禁吸煙或生火。
                        <br/>
                        10. 預約一經確認，租場費將不獲退還。
                        <br/>
                        11. 客人只可以改期一次。如客人需要改期，必須於租場日至少7天前提出改期申請，否則需要支付該次總租場費之30%金額為手續費。新的拍攝日只可以選原拍攝日起計30天內的日子 （例如：原拍攝日為7月8日，新的拍攝日只可以選擇7月9 -8月7日其中一天作為新拍攝日。）如客人選擇的新拍攝日已被租用，客人只可選擇其他未被租用的日子。                  
                        <br/>
                        12. 租場設有使用人數上限為15人，超出後需額外收取每人$50。
                        <br/>
                        **212 STUDIO 保留最後決策權
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography id="transition-modal-title" variant="h6" component="h2">
                        問與答
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ mt: 2 }} textAlign={"left"}>
                        ◽ 怎樣預約場地？
                        <br/>
                        可以 Instagram DM 查詢，我們會發送價目表及租場細則給大家。當確定預約時段後，就需要先付六成租場費用作訂金，餘額需要在租場當天繳付。付款方式可以用PayMe/FPS/銀行轉賬支付。
                        <br/>
                        <br/>
                        ◽ 怎樣預約場地？
                        <br/>
                        可以 Instagram DM 查詢，我們會發送價目表及租場細則給大家。當確定預約時段後，就需要先付六成租場費用作訂金，餘額需要在租場當天繳付。付款方式可以用PayMe/FPS/銀行轉賬支付。
                        <br/>
                        <br/>
                        ◽ 租借場地設有最低消費嗎？
                        <br/>
                        每個租用時段需時少兩小時或以上
                        <br/>
                        <br/>
                        ◽ 租借場地設有最低消費嗎？
                        <br/>
                        每個租用時段需時少兩小時或以上
                        <br/>
                        <br/>
                        ◽ 除了租場費用，還有其他額外收費嗎？
                        <br/>
                        212 Studio Venue 另設有燈光器材、攝影背景紙租借服務，每個項目都需要額外支付$100。如需要租借以上項目，可以在確認預訂場地時，一同預約所需設備，好讓我們預先為大家安排。但如果有任何損壞，須照價賠償。
                        <br/>
                        <br/>
                        ◽ 場地收費模式？
                        <br/>
                        212 Studio Venue 租借服務收費分為2種，「非商業攝影」及「商業攝影」，以每小時計算。
                        <br/>
                        「非商業攝影」 - 家庭照、情侶照、婚紗照、Dance Cover或IG shop小店產品相等
                        <br/>
                        「商業攝影」 - 電影拍攝、廣告製作、MV、Youtube Channel 影片、公司短片、直播、工作坊等。
                        <br/>
                        <br/>
                        ◽ 可以預約參觀場地？
                        <br/>
                        歡迎大家跟我們預約參觀場地，先視察一下場地佈置及環境是否合適，以便各位安排拍攝工作。每次參觀時間約15-30分鐘，建議預早跟我們預約時間安排參觀。
                        <br/>
                        <br/>
                        ◽ 有沒有化妝台提供？
                        <br/>
                        如果客人已預約租用場地，便可以免費使用化妝台。但如果沒有預約租用場地，只想租用化妝台，即需要支付每小時$50。
                        <br/>
                        <br/>
                        ◽ 是否可以攜帶寵物到場地？
                        <br/>
                        我們是Pets friendly 場地，歡迎客人攜帶1至2隻寵物到場，如果希望攜帶更多寵物，就需要聯絡我們另作查詢。
                        <br/>
                        <br/>
                        ◽ 攜帶寵物到場需要收額外費用？
                        <br/>
                        不需要支付額外費用，但如果損壞或弄污場內任何物品，就需要收取$1500清潔費，所以希望大家愛惜場地，小心使用。
                        <br/>
                        <br/>
                        ◽ 有人數限制嗎？
                        <br/>
                        每節租場容納人數上限為15人，希望大家有足夠走動空間。如超出上限，需額外收取每人$50。
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography id="transition-modal-title" variant="h6" component="h2">
                        Rental Terms and Conditions
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ mt: 2 }} textAlign={"left"}>
                        1) The rental fee does not cover lighting equipment or paper backdrops.
                        <br/>
                        2) Photography services such as lighting setup, camera operators, photography assistants, and computer usage are not included in the rental fee.
                        <br/>
                        3) We kindly request that you maintain the cleanliness of the studio and properly dispose of any rubbish. Failure to do so will result in a cleaning fee of $1500.
                        <br/>
                        4) Before departing, please ensure that all equipment is returned to its designated location. Failure to do so will result in a tidying fee of $1500.
                        <br/>
                        5) If the allotted time is exceeded by more than 15 minutes, it will be considered as an additional hour. The end time will depend on the customer's departure.
                        <br/>
                        6) Customers will be held financially responsible for any misuse or damage to the studio equipment or items.
                        <br/>
                        7) 212 STUDIO will promptly charge customers for any additional fees if the studio is used for purposes other than the original booking. Failure to comply may result in a ban from future studio usage.
                        <br/>
                        8) 212 STUDIO will not assume any responsibility or provide compensation for any damages or losses incurred by customers.
                        <br/>
                        9) Smoking or starting fires is strictly prohibited inside the studio.
                        <br/>
                        10) Once a booking is confirmed, the rental fee is non-refundable.
                        <br/>
                        11) Customers are allowed to reschedule their booking only once. If rescheduling is requested, it must be submitted at least 7 days prior to the original booking date. Otherwise, a handling fee of 30% of the total rental fee will be charged. The new shooting date can only be chosen within 30 days from the original shooting date (For example: If the original shooting date is July 8th, the customer can only choose a new date between July 9th and August 7th). If the new shooting date has already been booked by another customer, you may only select a date that has not been reserved.
                        <br/>
                        12) The studio has a maximum capacity of 15 people. Any additional persons will be charged an extra fee of $50 each.
                        <br/>
                        **Please note that 212 STUDIO reserves the right to make the final decision regarding any matters not explicitly addressed in the terms and conditions.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography id="transition-modal-title" variant="h6" component="h2">
                        FAQ
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ mt: 2 }} textAlign={"left"}>
                        ◽ How to book ?
                        <br/>
                        Customers can contact us via Instagram direct message. We will send you the price list and terms and questions. To confirm your booking, customers are required to pay a 60% deposit of the rental fee, with the remainder payable on the shooting day. Payment methods accepted include PayMe, FPS, and bank transfer.
                        <br/>
                        <br/>
                        ◽ Is there a minimum charge?
                        <br/>
                        Each rental period requires a minimum stay of two hours.
                        <br/>
                        <br/>
                        ◽ Is there any additional charges besides the rental fee?
                        <br/>
                        We also offer rental services for lighting equipment and photography backdrops, with an additional fee of $100 for each item. If you require any of these items, please let us know when confirming your venue reservation so that we can make the necessary arrangements in advance. However, please note that any damages incurred will be charged at the full replacement cost.
                        <br/>
                        <br/>
                        ◽ The pricing structure
                        <br/>
                        The rental fees for 212 Studio Venue are divided into two categories: "Non-commercial Photography" and "Commercial Photography," calculated on an hourly basis.
                        <br/>
                        "Non-commercial Photography" includes family portraits, couple shoots, wedding photos, dance covers, or product photography for IG shops.
                        <br/>
                        "Commercial Photography" includes film shoots, advertising productions, music videos, YouTube channel videos, corporate videos, live streaming, workshops, and more.
                        <br/>
                        <br/>
                        ◽ Can we arrange a visit to the venue?
                        <br/>
                        We welcome you to schedule a visit to the venue to assess the layout and suitability of the environment for your photography needs. Each visit typically takes around 15-30 minutes, and we recommend contacting us in advance to arrange a visit.
                        <br/>
                        <br/>
                        ◽ Is there a make-up table avaliable?
                        <br/>
                        If you have already booked the venue rental, you can use the makeup station free of charge. However, if you only wish to rent the makeup station without booking the venue, there will be an additional fee of $50 per hour.
                        <br/>
                        <br/>
                        ◽ Are pets allowed in the venue?
                        <br/>
                        We are a pet-friendly venue and welcome guests to bring 1-2 pets. If you wish to bring more pets, please contact us for further inquiries.
                        <br/>
                        <br/>
                        ◽ Do we need to pay extra for bring our pets to the venue?
                        <br/>
                        There is no additional fee for pets, but please be aware that any damages or stains on the premises will incur a $1500 cleaning fee. We kindly ask all guests to take care of the venue and use it responsibly.
                        <br/>
                        <br/>
                        ◽ Is there is maximum number of people?
                        <br/>
                        The maximum capacity for each rental session is 15 people to ensure sufficient space for movement. If the number of attendees exceeds this limit, an additional fee of $50 per person will be charged.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <button type="button" className="modal-bottom-button black-button" onClick={() => rentalOnSubmit()}>已閱並接受所有細則，繼續租用 Read and Accept all, proceed rental</button>
                  <button type="button" className="modal-bottom-button white-button" onClick={closeNoticeModal}>返回 Back</button>
                </Box>
              </Fade>
            </Modal>
          </>
        );
      }else{
        return(<h3></h3>);
      }
    }
    return(
      <>
        {display(props)}
      </>
    );
}
