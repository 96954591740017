import React, { useState, createRef } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import { useScreenshot, createFileName } from "use-react-screenshot";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import Logo from '../../logo.png';
import LogoBackground from '../../img/Watermark_Square.png';
import PaymentSuccessImg from '../../img/check-circle.png';

import Header from '../../components/Header.js';

export default function ProfilePaymentSuccess() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const { token_id } = useParams();
  const token_detail = JSON.parse(LocalStorageTTL.getWithExpiry('token_detail'));
  //--popup modal
  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async e => {
    e.preventDefault();
  };
  //--
  const savePaymentImage = () => {
    downloadScreenshot();
    handleOpen();
  };
  //--
  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });
  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);
  //--
  console.log("getSessionValue");
  console.log(token_detail);
  if(token_detail){
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Header />
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3',
                    backgroundImage: `url(${LogoBackground})`,
                    backgroundPosition:'center'
                  }}
                  ref={ref}
                >
                  <CardContent>
                    <div className="app-payment-success-container">
                      <img className="app-payment-success-img" src={PaymentSuccessImg}/>
                      <div className="app-payment-success-text">
                        購買成功！
                      </div>
                      <div className="app-payment-success-text">
                        Purchase success!
                      </div>
                    </div>
                    <hr/>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                      variant="body1"
                    >
                      <div className="app-profile-text-block">
                        {token_detail.created_time.substring(0,10)}
                      </div>
                      <hr/>
                      <div className="app-profile-text-block">
                        購買金額 Amount - HKD${token_detail.price}
                      </div>
                      <div className="app-profile-text-block">
                        付款方法 Payment Method- {token_detail.payment_method}
                      </div>
                      <div className="app-profile-text-block">
                        編號 No. - {token_detail.id}
                      </div>
                      <div className="app-profile-text-block">
                        購買內容 Content - 套票 Package {token_detail.token_num}張
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        <img className="app-logo-img" src={token_detail.image_url}/>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="app-page-bottom">
                  <div className="app-bottom">
                    <div className="app-bottom-container">
                      <button type="button" className="app-bottom-container-back" onClick={event =>  window.location.href='/home/account/tokens'}>返回 Back</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
